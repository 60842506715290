@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.smooth-image {
  transition: opacity 1s;
}
.image-visible {
  opacity: 1;
}
.image-hidden {
  opacity: 0;
}
.smooth-preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(245, 245, 245, 0.34);
}
.smooth-preloader .loader {
  background-image: linear-gradient(to right, #e6e6e6, #ffffff, #e6e6e6);
  width: 100%;
  height: 100%;
  display: inline-block;
  background-size: 200%;
  animation: preloader-block 2s linear 0s infinite reverse forwards;
}
.smooth-image-wrapper {
  position: relative;
}

.smooth-no-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fcfcfc;
  text-transform: capitalize;
}
